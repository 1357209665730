.Button {
  border: none;
  display: block;
  border-radius: 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  transition: 0.1s;

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
}

.fill {
  width: 100%;
}

.medium {
  padding: 8px 16px;
}

.large {
  padding: 12px 24px;
}

.primary {
  color: var(--white);
  background-color: var(--black);
}

.primary:hover,
.primary:focus {
  background-color: #232b34;
}

.secondary {
  color: var(--text-color);
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
}

.secondary:hover,
.secondary:focus {
  border: 1px solid var(--primary);
  color: var(--primary);
}

.auth {
  background-color: var(--success);
  color: var(--white);
}

.auth:hover,
.auth:focus {
  background-color: var(--success-hover);
}

.start {
  background-color: var(--success);
  color: var(--white);
}

.start:hover,
.start:focus {
  background-color: var(--success-hover);
}

.stop {
  background-color: var(--error);
  color: var(--white);
}

.stop:hover,
.stop:focus {
  background-color: var(--error-hover);
}
