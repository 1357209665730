.Table_Container {
  width: 100%;
  background-color: var(--background-color);
  color: var(--text-color);
  transition: background-color 0.3s ease, color 0.3s ease;
}

.container-button {
  display: flex;
  flex-direction: column;
}

.container-button-editable {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.active-row {
  background-color: var(--primary) !important;
  color: white !important;
}

.active-row td {
  background-color: var(--primary) !important;
  color: white !important;
}

.name-filter-dropdown {
  padding: 8px;
  border-radius: 6px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, .2);
}

.ant-table-placeholder {
  /*  TODO as `useSortable` works incorrectly "No Data" placeholder is overlapping filter's dropdowns   */
  z-index: 1 !important;
}
