.themeToggle {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.themeToggle button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: var(--text-color);
  transition: color 0.3s ease;
}

.themeSwitch {
  /* Оставляем только стили, которые не конфликтуют с ConfigProvider */
}

/* Styles for icons inside Switch */
.themeSwitch :global(.anticon) {
  font-size: 14px;
  color: #000000; /* Black color for icons for better contrast */
}

/* Styles for Switch on hover */
.themeSwitch:hover {
  opacity: 0.8;
}
