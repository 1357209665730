.wrapper {
  min-height: 100vh;
  background-color: var(--background-color);
  transition: background-color 0.3s ease;
}

.main {
  padding: 6px 6px;
  background-color: var(--secondary-background);
  transition: background-color 0.3s ease;
}
