:root {
  /* Common colors */
  --black: #111827;
  --white: #ffffff;
  --dark-gray: #d1d5db;
  --light-gray: #f3f4f6;
  --primary: #1890ff;
  --primary-hover: #40a9ff;
  --success: #064e3b;
  --success-hover: #65d12e;
  --warning: #faad14;
  --warning-hover: #ffc53d;
  --error: #f5222d;
  --error-hover: #ff4d4f;
  --font-family: 'Noto Sans', sans-serif;
  --switch-unchecked: #d9d9d9;
  --switch-unchecked-hover: #b5b5b5;

  /* Light theme (default) */
  --background-color: var(--white);
  --text-color: var(--black);
  --secondary-background: var(--light-gray);
  --border-color: var(--dark-gray);
}

/* Dark theme */
[data-theme='dark'] {
  --background-color: #1f2937;
  --text-color: #f9fafb;
  --secondary-background: #111827;
  --border-color: #374151;
}

body {
  margin: 0;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  line-height: 1.5;
  background-color: var(--background-color);
  color: var(--text-color);
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Apply theme variables to portals */
#portal-root,
.ant-drawer-root,
.ant-modal-root,
.ant-dropdown-root,
.ant-tooltip-root,
.ant-popover-root,
.ant-select-dropdown-root {
  --background-color: var(--background-color);
  --text-color: var(--text-color);
  --secondary-background: var(--secondary-background);
  --border-color: var(--border-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Basic styles for custom components */
h1, h2, h3, h4, h5, h6, p, span, div, label, a, button, input, textarea, select, option {
  color: var(--text-color);
  transition: color 0.3s ease;
}

/* Switch component styles */
:where(.css-dev-only-do-not-override-bpcu6v).ant-switch:not(.ant-switch-checked) {
  background: var(--switch-unchecked);
}

:where(.css-dev-only-do-not-override-bpcu6v).ant-switch:not(.ant-switch-checked):hover:not(.ant-switch-disabled) {
  background: var(--switch-unchecked-hover);
}
