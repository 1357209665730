/* Styles for RecordModal */
.record-modal {
  width: 80vw !important;
  max-width: 1200px !important;
}

.record-modal .ant-modal-content {
  border-radius: 8px;
  overflow: hidden;
}

.record-modal .ant-modal-header {
  padding: 16px 24px;
}

.record-modal .ant-modal-title {
  font-weight: 500;
  font-size: 16px;
}

.record-modal .ant-modal-body {
  padding: 24px;
}

.record-modal .ant-form-item {
  margin-bottom: 16px;
}

.record-modal .ant-modal-footer {
  padding: 10px 16px;
}
