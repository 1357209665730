.Player_Container {
  width: 100%;
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  color: var(--text-color);
  transition: color 0.3s ease;
}

.Buttons_Container {
  display: flex;
  justify-content: flex-start;
}

/* Style for the video */
video {
  width: 98%;
  height: 100%;
}

.video-wrapper.mirrored video {
  transform: scaleX(-1);
  transform-origin: center;
}

.Segment_Title {
  display: block;
  float: right;
  color: var(--text-color);
  transition: color 0.3s ease;
}

.controls-btn {
  background-color: var(--black);
}
