/* Login page styles */
.login-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-box {
  background-color: var(--background-color);
  padding: 40px 40px 20px 40px;
  border-radius: 24px;
  border: 1px solid var(--border-color);
  text-align: center;
  width: 663px;
}

.ant-form-item {
  margin-bottom: 20px;
}

.login-box .ant-input,
.login-box .ant-input-password {
  border-radius: 2px;
  background-color: var(--background-color) !important;
  color: var(--text-color) !important;
  border-color: var(--border-color) !important;
}

.login-box .ant-input:hover,
.login-box .ant-input-password:hover {
  border-color: var(--primary) !important;
}

.login-box .ant-input:focus,
.login-box .ant-input-password-focused,
.login-box .ant-input-affix-wrapper-focused {
  border-color: var(--primary) !important;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2) !important;
}

.login-box .ant-input-affix-wrapper {
  background-color: var(--background-color) !important;
  border-color: var(--border-color) !important;
}

.login-box .ant-input-affix-wrapper .ant-input {
  background-color: transparent !important;
}

/* Styles for outlined inputs */
.login-box :where(.css-dev-only-do-not-override-1pv5er).ant-input-outlined,
.login-box :where(.css-dev-only-do-not-override-1pv5er).ant-input-affix-wrapper {
  background-color: var(--background-color) !important;
  border-color: var(--border-color) !important;
  color: var(--text-color) !important;
}

.login-box :where(.css-dev-only-do-not-override-1pv5er).ant-input-outlined:hover,
.login-box :where(.css-dev-only-do-not-override-1pv5er).ant-input-affix-wrapper:hover {
  border-color: var(--primary) !important;
}

.login-box :where(.css-dev-only-do-not-override-1pv5er).ant-input-outlined:focus,
.login-box :where(.css-dev-only-do-not-override-1pv5er).ant-input-affix-wrapper-focused {
  border-color: var(--primary) !important;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2) !important;
}

/* Override browser autofill styles */
.login-box input:-webkit-autofill,
.login-box input:-webkit-autofill:hover,
.login-box input:-webkit-autofill:focus,
.login-box input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--background-color) inset !important;
  -webkit-text-fill-color: var(--text-color) !important;
  transition: background-color 5000s ease-in-out 0s;
  caret-color: var(--text-color);
}

/* Firefox specific autofill override */
.login-box input:autofill {
  background-color: var(--background-color) !important;
  color: var(--text-color) !important;
}

/* For newer browsers that support the standard */
.login-box input:-internal-autofill-selected {
  background-color: var(--background-color) !important;
  color: var(--text-color) !important;
}
