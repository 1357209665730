.map_controls {
    display: flex;
    justify-content: flex-start;
}

.map_container {
    width: 100%;
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--background-color);
    color: var(--text-color);
    transition: background-color 0.3s ease, color 0.3s ease;
}

.ui-button {
    background-color: var(--background-color);
    border: 1px solid var(--border-color);
    color: var(--text-color);
    padding: 5px 10px;
    margin: 5px;
    cursor: pointer;
    font-size: 14px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
    outline: none;
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

/* Google Maps styles are preserved in their original form */
