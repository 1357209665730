.App {
  text-align: center;
  background-color: var(--secondary-background);
  transition: background-color 0.3s ease;
}

.Container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 90vh;
  background-color: var(--secondary-background);
  transition: background-color 0.3s ease;
}

.active-row {
  background-color: var(--primary) !important;
  color: white;
}

.active-row:hover td {
  background-color: var(--primary) !important;
  color: white;
}
