.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--background-color);
  padding: 13px 44px;
  color: var(--text-color);
  border-bottom: 1px solid var(--border-color);
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

.hideHeader {
  display: none;
}

.navBar {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  width: 100%;
}

.navLinks {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 10px 4px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  transition: border-color 0.3s ease;
}

.navLinks li {
  display: inline;
}

.rightSection {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
}
